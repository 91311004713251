export const selectOptions = {
  availability: [
    "FULLTIMEIMMEDIATELY",
    "FULLTIMETHIRTYDAYS",
    "OPENTOOFFERS",
    "PARTTIME",
    "READYTOINTERVIEW",
    "UNAVAILABLE",
  ],
  educationDegreeTypes: [
    "DOCTORAL_OR_EQUIVALENT",
    "MASTERS_OR_EQUIVALENT",
    "BACHELORS_OR_EQUIVALENT",
    "ASSOCIATES_OR_EQUIVALENT",
    "UPPER_SECONDARY_EDUCATION",
  ],
  careerFormatTypes: ["FULLTIME", "PARTTIME", "SELFEMPLOYED", "CONTRACT"],
  careerLocationTypes: ["ONSITE", "REMOTE", "HYBRID"],
  employmentType: [
    "CONTRACT",
    "DTCPERMPLACEMENT",
    "DTCSOLUTION",
    "PERMPLACEMENT",
    "CONTRACTTOPERM",
    "SOLUTION",
    "IC",
  ],
  userType: ["ADMIN", "CUSTOMER", "FREELANCER", "TORC_RECRUITER", "UNKNOWN"],
  userStatus: ["ACTIVE", "INACTIVE", "SUSPENDED"],
  status: [
    "ACTIVE",
    "CANCELLED",
    "DELETED",
    "DRAFT",
    "FULFILLED",
    "PENDINGAPPROVAL",
    "RDFULFILLED",
    "NONSTRIKEZONE",
  ],
  timeCommitment: ["FULLTIME", "PARTTIME"],
  languageLevel: ["BASIC", "CONVERSATIONAL", "FLUENT", "FULLY_FLUENT"],
  commonSocialLinks: [
    "PORTFOLIO",
    "GITHUB",
    "BADGR",
    "LINKEDIN",
    "TWITTER",
    "INSTAGRAM",
    "FACEBOOK",
    "DEV",
  ],
  freelanderSocialLinks: ["HASHNODE", "STACKOVERFLOW"],
  customerSocialLinks: ["CALENDAR"],
  matchStatus: [
    "ACCEPTED",
    "APPLIED",
    "MATCHED",
    "MOREINFO",
    "REJECTEDBYCUSTOMER",
    "REJECTEDBYMEMBER",
    "REJECTEDBYMATCHER",
    "SHORTLISTED",
    "PASSEDON",
    "SKIPPED",
  ],
  moreInfoRequest: [
    "INTERVIEWCANDIDATE",
    "REQUESTINFOFROMCANDIDATE",
    "REQUESTINFOFROMTORC",
    "OTHER",
  ],
  reasonForRejection: [
    "ENGLISHLANGDEFICIT",
    "INADEQUATESKILLLVL",
    "LACKEXPERIENCE",
    "LACKKNOWLEDGE",
    "LOCATIONNOTFEASIBLE",
    "MISSINGSKILL",
    "NOTAVAILABLE",
    "OTHER",
    "RATEHIGH",
    "RATELOW",
    "STACKNOTDESIRED",
  ],
  otherLinkType: ["EXTERNALLINK", "GENERAL", "OTHER", "RESULT", "RESUME"],
  otherLinkVisibility: ["CUSTOMER", "PUBLIC", "PRIVATE"],
  projectsCaseStudiesWorkTypes: ["PROJECT", "CASESTUDY"],
  priorityLevel: ["P1", "P2"],
  jobOppVisibilityLevel: ["PRIVATE", "LIMITED", "PUBLIC"],
};

export const readOnlyFields = {
  user: [
    "creator",
    "updater",
    "status",
    "visibility",
    "profileCompletion",
    "identity_username",
    "referralCode",
    "referralCount",
    "githubAccessToken",
    "githubRefreshToken",
    "stats",
    "milestoneRewards",
  ],
  jobRole: ["createdAt", "updatedAt"],
  jobOpportunity: [],
  application: ["createdAt", "updatedAt"],
  match: [],
};

export const integerFields = {
  user: [
    "profileCompletion",
    "location.countryId",
    "location.locationId",
    "location.stateId",
    "ratePerHour.value",
    "salary.value",
    "referralCount",
    "additionalMarkup",
    "noticePeriod",
  ],
  jobRole: [],
  jobOpportunity: [
    "maxRate.value",
    "minRate.value",
    "minSalary.value",
    "maxSalary.value",
    "jobLength",
    "requiredPositions",
    "openPositions",
    "jobLengthInWeeks",
    "timeOverlap",
  ],
  application: ["preferredRate.value"],
  match: ["rate.value", "customerRate.value"],
};

export const selectFields = {
  user: ["availability", "userType"],
  jobRole: [],
  jobOpportunity: ["visibilityLevel", "status", "timeCommitment"],
  application: [],
  match: ["status", "moreInfoRequest"],
};
