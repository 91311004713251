import { Button } from "@aws-amplify/ui-react";

import { ReactComponent as Logo } from "../../assets/logo.svg";

const SimpleHeader = ({ signOut }) => (
  <header className="header flex items-center p-4 shadow-sm justify-between">
    <div>
      <Logo className="h-10" alt="Torc" />
    </div>
    <Button size="small" onClick={signOut}>
      Sign out
    </Button>
  </header>
);

export default SimpleHeader;
