import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

import awsConfig from "../../aws-exports";
import { AlertProvider } from "../../context/Alert";
import ErrorBoundary from "../ErrorBoundary";
import PrivateRoute from "../PrivateRoute";
import UserSearch from "../UserSearch";
import UserEdit from "../UserEdit";
import JobRoleEdit from "../JobRoleEdit";
import JobRoleCreate from "../JobRoleCreate";
import JobRoleSearch from "../JobRoleSearch";
import JobOpportunitySearch from "../JobOpportunitySearch";
import JobOpportunityEdit from "../JobOpportunityEdit";
import ApplicationSearch from "../ApplicationSearch";
import ApplicationEdit from "../ApplicationEdit";
import MatchEdit from "../MatchEdit";
import MatchSearch from "../MatchSearch";
import CognitoGroups from "../CognitoGroups";
import modifyAwsConfig from "../../helpers/modifyAwsConfig";

import { ReactComponent as Logo } from "../../assets/logo.svg";

modifyAwsConfig(awsConfig);
Amplify.configure(awsConfig);

const components = {
  SignIn: {
    Header: () => <Logo className="h-14 w-40 mt-8 mx-auto" alt="Torc" />,
  },
};

const App = () => {
  return (
    <ErrorBoundary>
      <AlertProvider>
        <Authenticator
          socialProviders={["google"]}
          hideSignUp={true}
          components={components}
          className="pt-[20vh]"
        >
          {({ signOut, user }) => (
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={<PrivateRoute user={user} signOut={signOut} />}
                >
                  <Route path="/users" element={<UserSearch />} />
                  <Route path="/users/:query" element={<UserEdit />} />
                  <Route path="/groups" element={<CognitoGroups />} />
                  <Route path="/job-roles" element={<JobRoleSearch />} />
                  <Route path="/job-roles/create" element={<JobRoleCreate />} />
                  <Route
                    path="/job-roles/:jobRoleId"
                    element={<JobRoleEdit />}
                  />
                  <Route
                    path="/job-opportunities"
                    element={<JobOpportunitySearch />}
                  />
                  <Route
                    path="/job-opportunities/:jobOpportunityId"
                    element={<JobOpportunityEdit />}
                  />
                  <Route path="/applications" element={<ApplicationSearch />} />
                  <Route
                    path="/applications/:applicationId"
                    element={<ApplicationEdit />}
                  />
                  <Route path="/matches" element={<MatchSearch />} />
                  <Route path="/matches/match" element={<MatchEdit />} />
                </Route>
              </Routes>
            </BrowserRouter>
          )}
        </Authenticator>
      </AlertProvider>
    </ErrorBoundary>
  );
};

export default App;
